import React, { useState } from 'react';
import moment from 'moment';
import classNames from 'classnames';

// All available reactions
const reactions = [
    {
        type: 'eyes',
        emoji: '👀',
    },
    {
        type: 'celebrate',
        emoji: '🎉',
    },
    {
        type: 'heart',
        emoji: '❤️',
    },
];

// Super basic attempt at limiting reactions.
// We store reactions the users' localStorage and use that
// for checking if an user has already reacted to a post.
// This is certainly not a good solution, but it is good enough for
// this project and also helps visualize reactions.
const hasReacted = (post, reaction) => {
    const entry = localStorage.getItem(post.id);

    if (entry === null) {
        return false;
    }

    const existingReactions = JSON.parse(entry);

    return existingReactions.indexOf(reaction.type) !== -1;
};

// Save the reaction in the users' localStorage
const saveReaction = (post, reaction) => {
    let entry = localStorage.getItem(post.id);
    if (entry) {
        entry = JSON.parse(entry);
    } else {
        entry = [];
    }

    entry.push(reaction.type);
    localStorage.setItem(post.id, JSON.stringify(entry));
}

export default function Post({ post, updatePost }) {

    const addReaction = async (reaction) => {
        saveReaction(post, reaction);

        // Create the request and send it to the backend
        const requestOptions = {
            method: 'PATCH',
            body: JSON.stringify({
                reaction: reaction.type,
            }),
        };

        await fetch(`${process.env.REACT_APP_WORKER_URL}/posts/${post.id}`, requestOptions);

        // Update the post with the new reaction amount
        post[reaction.type] ? post[reaction.type]++ : post[reaction.type] = 1;
        updatePost(post);
    };

    return (
        <div className='p-4 bg-white rounded-lg shadow'>
            <div>
                <div className="flex space-x-3">
                    <div className="flex-shrink-0">
                        <img className="h-10 w-10 rounded-full" src={'https://placekitten.com/200/200?' + post.username} alt="" />
                    </div>
                    <div className="min-w-0 flex-1">
                        <p className="text-sm font-medium text-gray-900 truncate">
                            {post.username}
                        </p>
                        <p className="text-sm text-gray-500">
                            {moment(post.createdAt).format('LLL')}
                        </p>
                    </div>
                </div>
                <h2 className="mt-4 text-base font-medium text-gray-900 truncate">
                    {post.title}
                </h2>
            </div>
            <div
                className="mt-2 text-sm text-gray-700 space-y-4 break-all"
            >
                {post.content}
            </div>
            <div className="mt-4 flex justify-between space-x-8">
                <div className="flex space-x-2">
                    {reactions.map((reaction, idx) => (
                        <button
                            key={idx}
                            type='button'
                            onClick={() => addReaction(reaction)}
                            className={classNames(
                                "bg-gray-100 px-1 py-0.5 border border-gray-200 rounded-lg inline-flex items-center text-sm select-none",
                                { 'cursor-not-allowed bg-indigo-200 border-indigo-300': hasReacted(post, reaction) }
                            )}
                            disabled={hasReacted(post, reaction)}
                        >
                            <span className="inline-flex space-x-2">
                                {reaction.emoji}
                                <span className="ml-1 font-medium text-gray-900">{post[reaction.type] ?? 0}</span>
                            </span>
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
}