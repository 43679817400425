import { useEffect, useState } from "react";
import Post from "./components/Post";
import CreatePost from "./components/CreatePost";

function App() {
    const [posts, setPosts] = useState(null);

    const [sortBy, setSortBy] = useState('latest');

    const sortPosts = (posts) => {
        switch (sortBy) {
            case 'latest': {
                return [...posts].sort((a, b) => b.createdAt - a.createdAt);
            }
            case 'oldest': {
                return [...posts].sort((a, b) => a.createdAt - b.createdAt);
            }
            default: {
                return null;
            }
        }
    };

    // Helper function to update a specific post
    const updatePost = (post) => {
        const newPosts = posts.filter(x => x.id !== post.id);
        newPosts.push(post);

        setPosts(sortPosts(newPosts));
    };

    const fetchPosts = () => {
        fetch(`${process.env.REACT_APP_WORKER_URL}/posts`)
            .then(response => response.json())
            .then(data => setPosts(sortPosts(data)));
    };

    useEffect(() => {
        fetchPosts();

        // We periodically fetch the posts as we can't directly add new posts directly in the frontend.
        // The reaction feature relies on post ids, which are generated on the server side.
        // Due to the specification that the add-post route returns 'success' instead of the post object,
        // we can't directly insert the new post but have to update them periodically. This has also the side-effect
        // that new posts can take a few seconds to be visible, due to the eventual consistency of KV.
        const interval = setInterval(fetchPosts, 20 * 1000);

        return () => {
            clearInterval(interval);
        }
    }, []);

    useEffect(() => {
        if (posts === null) {
            return;
        }

        setPosts(sortPosts([...posts]));
    }, [sortBy]);

    return (
        <div className='min-h-screen bg-gray-100 pb-16'>
            {posts === null ? (
                <p className='text-3xl font-bold text-center'>Loading...</p>
            ) : (
                <div className='w-1/3 mx-auto flex-col justify-center'>
                    <h1 className='text-3xl font-bold text-center'>Posts</h1>

                    <CreatePost updatePost={updatePost} />

                    <div className='my-2 w-1/3'>
                        <label htmlFor="sortBy" className="block text-sm font-medium text-gray-700">
                            Sort By
                        </label>
                        <select
                            id="sortBy"
                            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none text-sm rounded-md"
                            value={sortBy}
                            onChange={e => setSortBy(e.target.value)}
                        >
                            <option value='latest'>Latest</option>
                            <option value='oldest'>Oldest</option>
                        </select>
                    </div>
                    <div className="flex-col justify-center space-y-4 mt-4">
                        {posts.map((post, idx) =>
                            <Post key={idx} post={post} updatePost={updatePost} />
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}

export default App;
