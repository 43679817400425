import classNames from 'classnames';
import React, { useState } from 'react';

export default function CreatePost() {
    const [username, setUsername] = useState('');
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');

    const [flashMessage, setFlashMessage] = useState(null);

    const createPost = async () => {
        // Check if all fields are filled out
        if (username.trim().length === 0 || title.trim().length === 0 || content.trim().length === 0) {
            setFlashMessage({ type: 'error', content: 'Please fill out all fields!' });

            return;
        }

        // Create the request and send it to the server.
        // We also need to set the 'credentials' option to 'include'
        // so that 'set-cookie' headers in the response will be processed by the browser.
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({
                title,
                username,
                content,
            }),
            credentials: 'include',
        };

        fetch(`${process.env.REACT_APP_WORKER_URL}/posts`, requestOptions)
            .then(() => {
                setUsername('');
                setTitle('');
                setContent('');

                setFlashMessage({ type: 'info', content: 'Your post has been published and will automatically show up in a few seconds...' });

                setTimeout(() => setFlashMessage(null), 6 * 1000);
            })
            .catch(() => {
                setFlashMessage({ type: 'error', content: 'Whoops, something went wrong. Please try again later!' });
            });
    };

    return (
        <div className="flex-col space-y-4 p-4 mt-2 bg-white rounded-lg shadow">
            <div className="flex space-x-4">
                <div className="w-1/3 border border-gray-300 rounded-lg shadow-sm overflow-hidden">
                    <input
                        type='text'
                        placeholder='Username'
                        className='block w-full py-1 px-2 text-sm focus:outline-none'
                        value={username}
                        onChange={e => setUsername(e.target.value)}
                    />
                </div>
                <div className="w-2/3 border border-gray-300 rounded-lg shadow-sm overflow-hidden focus:ring-0">
                    <input
                        type='text'
                        placeholder='Title'
                        className='block w-full py-1 px-2 text-sm focus:outline-none'
                        value={title}
                        onChange={e => setTitle(e.target.value)}
                    />
                </div>
            </div>
            <div className="border border-gray-300 rounded-lg shadow-sm overflow-hidden focus:ring-0">
                <textarea
                    rows={3}
                    className="block w-full text-sm p-2 border-0 focus:outline-none"
                    placeholder="Content"
                    value={content}
                    onChange={e => setContent(e.target.value)}
                />
            </div>

            <div className="flex items-center space-x-4">
                <button
                    type='submit'
                    onClick={createPost}
                    className="px-3 py-1.5 text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700"
                >
                    Publish New Post
                </button>

                {flashMessage && (
                    <p className={classNames('text-sm', { 'text-red-500': flashMessage.type === 'error', 'text-green-500': flashMessage.type === 'info' })}>{flashMessage.content}</p>
                )}
            </div>
        </div>
    );
}